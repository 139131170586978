import React from "react";
import { Container, Column } from "../../components/System/Grids";
import PageIntro from "../../components/PageIntro/PageIntro";
import ArticleGridItem from "../../components/ArticleGridItem/ArticleGridItem";
import Layout from "../../components/Layout/Layout";
import blogInfo from "../../../data/blogInfo";

export default class BlogPageTemplate extends React.Component {
  render() {
    const { data, environment } = this.props;
    const content = blogInfo[environment.lang];

    let seo = {};
    if (content.seo.description) {
      seo.description = content.seo.description;
    }
    if (content.seo.title) {
      seo.title = content.seo.title;
    } else {
      seo.title = content.hero.title;
    }
    environment.seo = seo;
    environment.theme = "pink";

    return (
      <Layout environment={environment}>
        {content ? (
          <PageIntro bg="blogColorBg">
            <h1>{content.hero.title}</h1>
            <h3>{content.hero.subtitle}</h3>
          </PageIntro>
        ) : null}
        <Container>
          {data.articles.edges.map((edge) => {
            return (
              <Column w="100" key={edge.node.slug}>
                <ArticleGridItem node={edge.node} />
              </Column>
            );
          })}
        </Container>
      </Layout>
    );
  }
}
