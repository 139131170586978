const blogInfo = {
  en: {
    hero: {
      title: `The Blog`,
      subtitle: `Learning a language is a long journey. Let's stay motivated with articles and stories you can relate to.`,
    },
    seo: {
      title: `TDOA Blog, Easy stories and articles in Dutch an english`,
      description: `Easy, related and interesting articles written by The Dutch Online Academy. All articles are written by our team of Dutch teachers. Brought to you by The Dutch Online Academy`,
    },
  },
  nl: {
    hero: {
      title: `De Blog`,
      subtitle: `Een taal leren is een lange weg. Blijf gemotiveerd met artikelen en herkenbare verhalen.`,
    },
    seo: {
      title: `Gemakkelijke verhalen en artikelen in het Nederlands en Engels`,
      description: `Gemakkelijke, gerelateerde en interessante artikelen geschreven door The Dutch Online Academy. Alle artikelen zijn geschreven door ons team van Nederlandse docenten. Aangeboden door The Dutch Online Academy`,
    },
  },
};

export default blogInfo;
