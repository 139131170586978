import React from "react";
import { graphql } from "gatsby";
import BlogPageTemplate from "../templates/blog";

const environment = {
  lang: "nl",
  path: "/blog",
  altHrefLang: [
    {
      lang: "nl",
      path: "/blog",
    },
    {
      lang: "en",
      path: "/en/blog",
    },
  ],

  theme: "orange",
};
const BlogPageNl = (props) => {
  return <BlogPageTemplate environment={environment} {...props} />;
};
export default BlogPageNl;

export const query = graphql`
  query BlogPageNlQuery {
    articles: allContentfulArticle(
      filter: { node_locale: { eq: "nl" } }
      sort: { fields: [updatedAt], order: [DESC] }
    ) {
      edges {
        node {
          title
          slug
          node_locale
          contentful_id
          createdAt
          updatedAt
          description {
            childMarkdownRemark {
              excerpt(format: PLAIN)
            }
          }
          image {
            main: gatsbyImageData(width: 400, layout: CONSTRAINED)
          }
          __typename
        }
      }
    }
  }
`;
